import { Styles } from './styles/styles';

export const Down = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#000',
        height: '100vh',
        width: '100%',
      }}
    >
      <Styles />
      <p
        style={{
          color: '#fff',
          fontSize: '50px',
          fontFamily: 'initial',
          textTransform: 'unset',
        }}
      >
        This site is temporary down!
      </p>
    </div>
  );
};
